import React from "react";
import Home from "../pages/home.js"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from "../assets/images/logo.png"
import { Layout, Menu } from 'antd';
import { Input } from 'antd';
import {useState} from "react"

import { UserOutlined } from '@ant-design/icons';
// import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';


import "../assets/css/headerComp.css"
const HeaderComp = ()=>{

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
      };

      function logout(){
        localStorage.clear();
      }

    return(
        
        <>
        <div className="head">

         <button className="drawer-toggle" onClick={toggleDrawer}>
            ☰
          </button>
          {isDrawerOpen && (
            <div className="drawer">
              <div className="drawer-toggle" onClick={toggleDrawer}>
                ☰  
              </div>

              <ul>
                <li><Link to="/index/" onClick={toggleDrawer}>Home</Link></li>
                <li> <Link to="/index/myInvoice" ><h5>My Invoice</h5></Link></li>
                <li> <Link to="/index/coupons" ><h5>My Coupons</h5></Link></li>
                <li>   <Link to="/index/bookSlot" ><h5>Book a slot</h5></Link></li>
                <li> <Link to="/auth" onClick={()=>{localStorage.clear()}}><h5 >Logout</h5></Link></li>
              </ul>
            </div>
          )}

          <div className="logo" >
          <Link to="/index/" > <img src={logo} alt="" /></Link>
          </div>

          <div className="inpt">
            <Input className="loc" style={{width:"50%",margin:"2%",borderRadius:"0px"}} size="medium" placeholder="Location" prefix={<UserOutlined />} />
            
            <Input className="search" style={{margin:"2%",borderRadius:"0px"}} size="medium" placeholder="Search for services" prefix={<UserOutlined />} />
          </div>

          <Menu className="menu" style={{width:"30%"}} theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to="/index/bookSlot" ><h5>Book a slot</h5></Link>
            </Menu.Item>
            
            <Menu.Item key="2">
              <Link to="/index/myInvoice" ><h5>My Invoice</h5></Link>
            </Menu.Item>
            

            <Menu.Item key="3">
              <Link to="/index/coupons" ><h5>My Coupons</h5></Link>
            </Menu.Item>
            

            <Menu.Item key="4">
              <Link to="/auth" onClick={()=>{localStorage.clear()}}><h5 >Logout</h5></Link>
            </Menu.Item>
            

          </Menu>

          <Link to="/index/profile" > <div className="profile">

          </div></Link>
        </div>
       
        </>
    )
}


export default HeaderComp;
// import React, { useState } from 'react';


import React,{useRef,useState} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"

import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"


// import BookCard from '../components/bookCard.js';





import "../assets/css/bookSlot.css"
// import { calc } from 'antd/es/theme/internal';
import { useNavigate } from "react-router-dom";
import { baseUrll } from '../utils/baseUrl';

const BookCard = ({ item, updateTotalPrice }) => {
  
  const serviceId = item.id
  console.log(serviceId)


  const navigate = useNavigate();

  const userId = localStorage.getItem('user');
  const businessId = localStorage.getItem('business');
  const adminId = localStorage.getItem('admin');
  // console.log("safda",typeof(userId))
  // alert(businessId)

  
  if(!userId || !businessId || !adminId){

    navigate("/auth")
  }

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")
  var quantityVar = 0;
  // const [upcomAppointment,setUpcomAppointment] = useState("")

    const AddData = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;
        console.log("ids",typeof(userId),typeof(businessId),typeof(serviceId),quantityVar)
        const obj ={

          "userId": parseInt(userId),
          "businessId": parseInt(businessId),
          "serviceId": serviceId,
          "qty": quantityVar
          }
        const response = await fetch(baseUrl + `cart.add`,{
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
          body: JSON.stringify(obj)
          
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("dasfafs",result)
        // console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result);
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    const updateDataInc = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `cart.updateQty/${data.id}`,{
          method: 'PUT', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
          body: JSON.stringify({
            qty:quantity+1
            })
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("fsdhhfds",result);
        // // for(let i = 0 ; i < result.)
        // setData(result);
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    
    };

    const updateDataDec = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `cart.updateQty/${data.id}`,{
          method: 'PUT', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
          body: JSON.stringify({
            qty:quantity-1
            })
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("fsdhhfds",result);
        // // for(let i = 0 ; i < result.)
        // setData(result);
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    
    };


    const removeData = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `cart.remove/${data.id}`,{
          method: 'DELETE', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          }
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // const result = await response.json();
        // // console.log(result.upcomingAppointmen);
        // // for(let i = 0 ; i < result.)
        // setData(result);
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // fetchData();
  // }, []); // Empty dependency array means this effect runs once when the component mounts


  const [quantity, setQuantity] = useState(0);
  const [showControls, setShowControls] = useState(false);
  
  // const [countItems, setCountItems] = useState(0);
  
  const handleButtonClick = () => {
    setShowControls(true);
    handleIncrease();
    

  };

  const handleIncrease = () => {
    quantityVar++
    console.log("dsaquan",quantityVar)
    setQuantity((prevQuantity) => prevQuantity + 1);
    item.quantity = quantity+1;
    updateTotalPrice(item.price,item);
    if(quantity+1  == 1){

      AddData()

      
    }
    else{

      updateDataInc()
    }
  };

  const handleDecrease = () => {
    quantityVar--
    if(quantity==1){
        setShowControls(false)
    }
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);

      item.quantity = quantity-1;
      updateTotalPrice(-item.price,item);
    }
    if(quantity-1 ==0){
      removeData()
    }
    else{
      updateDataDec()
    }
  };

 var difference = 0
  const calculateDifference = () => {
    const [fromHours, fromMinutes] = item.time.from.split(':').map(Number);
    const [tillHours, tillMinutes] = item.time.till.split(':').map(Number);

    const date1 = new Date();
    const date2 = new Date();

    date1.setHours(fromHours, fromMinutes, 0, 0);
    date2.setHours(tillHours, tillMinutes, 0, 0);

    const diffInMilliseconds = date2 - date1;

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    difference = `${diffInHours} hours and ${minutes} minutes`;
  };

  // calculateDifference()

  return (
    <div className="cd">
    <div className="left">
      <h4>{item.serviceType}</h4>
      <h2>{item.serviceName}</h2>
      <p>{item.description}</p>
      <div className="same">
        <h2>${item.price} |</h2> 
        <p>{difference}</p>
                
      </div>
    </div>
    <div className="right">
                  
      <div className="up" style={{visibility:"hidden"}}>
  
        <img src={star} alt="" />
        <h5>4.4 (00 reviews) </h5>
  
      </div>
  
      <div className="down">
        {!showControls &&( <button onClick={handleButtonClick}>Add to Cart</button>)}
          {showControls && (
            <div className="quantity-controls">
              <button onClick={handleDecrease}>-</button>
              <span>{quantity}</span>
              <button onClick={handleIncrease}>+</button>
            </div>
          )}
      </div>
    </div>
  </div>
  );
};

export default BookCard;

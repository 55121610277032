/* eslint-disable react/prop-types */
const shapes = {
  circle: "rounded-[50%]",
  square: "rounded-[0px]",
  round: "rounded-[5px]",
};
const variants = {
  outline: {
    blue_gray_900_01:
      "border-blue_gray-900_01 border border-solid text-black-900_01",
  },
  fill: {
    white_A700: "bg-white-A700 shadow-md text-black-900_01",
    blue_gray_900_01: "bg-blue_gray-900_01 text-white-A700",
    green_bg_btn: " text-white-A700 bg-green-bg-btn",
  },
};

const sizes = {
  xs: "h-[28px] px-[34px] text-sm",
  sm: "h-[30px] px-[7px]",
  "2xl": "h-[64px] px-[15px] text-[22px]",
  lg: "h-[45px] px-[35px] text-xl",
  md: "h-[31px] px-[25px] text-base",
  xl: "h-[51px] px-[35px] text-xl",
};
const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xl",
  color = "white_A700",
  ...restProps
}) => {
  console.log(className);
  return (
    <button
      className={`flex flex-row items-center justify-center text-center cursor-pointer ${
        (shape && shapes[shape]) || ""
      } ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]?.[color]) || ""
      } ${className} `}
      {...restProps}
    >
      {console.log(variants[variant]?.[color])}
      {!!leftIcon && leftIcon} {children} {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };

import React,{useRef,useEffect,useState} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import file from "../assets/images/Group(1).png"
import download from "../assets/images/download-minimalistic_svgrepo.com.png"
import call from "../assets/images/Frame 583.png"
import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"
import tick from "../assets/images/tick.png"
import coupan from "../assets/images/Group.png"
import faq from "../assets/images/Vector.png"

import { FaChevronDown, FaChevronUp, FaMapMarkerAlt } from 'react-icons/fa';
import { CiLocationOn } from "react-icons/ci";
import { MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import "../assets/css/accordian.css"





import "../assets/css/bookSlot.css"
import "../assets/css/previousAppointments.css"
import "../assets/css/myInvoice.css"

import { useNavigate } from "react-router-dom";
import { baseUrll } from '../utils/baseUrl';

const { Meta } = Card;

const MyInvoice = () => {


  const navigate = useNavigate();

  const userId = localStorage.getItem('user');
  const businessId = localStorage.getItem('business');
  const adminId = localStorage.getItem('admin');
  console.log("safda",typeof(userId))
  // alert(businessId)

  
  if(!userId){
    navigate("/auth")
  }

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")

  // const [upcomAppointment,setUpcomAppointment] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `user.invoice/${userId}`,{
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result);
        console.log("nyaincobe",result)


      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts


  const sliderRef = useRef(null); // Create a ref for the Slider component

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // Go to previous slide
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // Go to next slide
  };


  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(quantity > 0 ? quantity - 1 : 0);
  };

    //   accordian related functions and use states

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false
  });
  const datePickerRef = useRef(null);

  const [isOpenTime, setIsOpenTime] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const timePickerRef = useRef(null);

  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const fetchLocations = () => {
    // Mock fetching data from a database
    return Promise.resolve([
      { id: 1, name: 'New York' },
      { id: 2, name: 'Los Angeles' },
      { id: 3, name: 'Chicago' },
    ]);
  };

  
  const toggleAccordionLocation = () => {
    setIsOpenLocation(!isOpenLocation);
  };

  const handleRadioChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    const loadLocations = async () => {
      const data = await fetchLocations();
      setLocations(data);
    };
    loadLocations();
  }, []);


  const toggleAccordionTime = () => {
    setIsOpenTime(!isOpenTime);
  };

  useEffect(() => {
    if (isOpenTime && timePickerRef.current) {
      // Trigger the time picker to open
      timePickerRef.current.setFocus();
    }
  }, [isOpenTime]);



  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    if (isOpen && datePickerRef.current) {
      // Trigger the date picker to open
      datePickerRef.current.setFocus();
    }
  }, [isOpen]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>

<section>
   
      <div className="titleInvoice">
      <h1>My Invoice</h1>  
      
      </div>

      <div className="card3 invoiceCard" style={{justifyContent:"space-between",padding:"20px"}}>

        <div className="cuts">


            <div className="sec1">
            <img src={cut} alt="" />
            </div>
            <div className="sec2">
            
            <h4>Services Status</h4>
            <h2>Mens Relax Parlour</h2>
            <p>350 $ paid</p>
            <div className="invoice">
                <img src={file} alt="" />
                <h4>Invoice</h4>
                <img src={download} alt="" />

            </div>
       
        </div>
        </div>
       
        <div className="sec3">
          <div className="up">
              
              <img src={share} alt="" />

              <img src={call} alt="" />

              <img src={whatsapp} alt="" />

          </div>

          <div className="downs">

            <button>Reschedule</button>
            {/* <h5>4.4 (00 reviews) </h5> */}
            
          </div>
        </div>
      </div>  
 
      <div className="card3 invoiceCard" style={{justifyContent:"space-between",padding:"20px"}}>

<div className="cuts">


    <div className="sec1">
    <img src={cut} alt="" />
    </div>
    <div className="sec2">
        
    <h4>Services Status</h4>
    <h2>Mens Relax Parlour</h2>
    <p>350 $ paid</p>
    <div className="invoice">
        <img src={file} alt="" />
        <h4>Invoice</h4>
        <img src={download} alt="" />

    </div>
   
</div>
</div>
   
<div className="sec3">
  <div className="up">
          
      <img src={share} alt="" />

      <img src={call} alt="" />

      <img src={whatsapp} alt="" />

  </div>

  <div className="downs">

    <button>Reschedule</button>
    {/* <h5>4.4 (00 reviews) </h5> */}
        
  </div>
</div>
</div>  



<div className="card3 invoiceCard" style={{justifyContent:"space-between",padding:"20px"}}>

<div className="cuts">


    <div className="sec1">
    <img src={cut} alt="" />
    </div>
    <div className="sec2">
        
    <h4>Services Status</h4>
    <h2>Mens Relax Parlour</h2>
    <p>350 $ paid</p>
    <div className="invoice">
        <img src={file} alt="" />
        <h4>Invoice</h4>
        <img src={download} alt="" />

    </div>
   
</div>
</div>
   
<div className="sec3">
  <div className="up">
          
      <img src={share} alt="" />

      <img src={call} alt="" />

      <img src={whatsapp} alt="" />

  </div>

  <div className="downs">

    <button>Reschedule</button>
    {/* <h5>4.4 (00 reviews) </h5> */}
        
  </div>
</div>
</div>  



<div className="card3 invoiceCard" style={{justifyContent:"space-between",padding:"20px"}}>

<div className="cuts">


    <div className="sec1">
    <img src={cut} alt="" />
    </div>
    <div className="sec2">
        
    <h4>Services Status</h4>
    <h2>Mens Relax Parlour</h2>
    <p>350 $ paid</p>
    <div className="invoice">
        <img src={file} alt="" />
        <h4>Invoice</h4>
        <img src={download} alt="" />

    </div>
   
</div>
</div>
   
<div className="sec3">
  <div className="up">
          
      <img src={share} alt="" />

      <img src={call} alt="" />

      <img src={whatsapp} alt="" />

  </div>

  <div className="downs">

    <button>Reschedule</button>
    {/* <h5>4.4 (00 reviews) </h5> */}
        
  </div>
</div>
</div>  


<div className="card3 invoiceCard" style={{justifyContent:"space-between",padding:"20px"}}>

<div className="cuts">


    <div className="sec1">
    <img src={cut} alt="" />
    </div>
    <div className="sec2">
        
    <h4>Services Status</h4>
    <h2>Mens Relax Parlour</h2>
    <p>350 $ paid</p>
    <div className="invoice">
        <img src={file} alt="" />
        <h4>Invoice</h4>
        <img src={download} alt="" />

    </div>
   
</div>
</div>
   
<div className="sec3">
  <div className="up">
          
      <img src={share} alt="" />

      <img src={call} alt="" />

      <img src={whatsapp} alt="" />

  </div>

  <div className="downs">

    <button>Reschedule</button>
    {/* <h5>4.4 (00 reviews) </h5> */}
        
  </div>
</div>
</div>  

  
</section>

      <Banner/>

                
    </>
  );
};

export default MyInvoice;
import React from "react";
import Home from "../pages/home.js"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import BookSlot from "../pages/bookASlot.js";
import BookAppointment from "../pages/bookAppointment.js";
import PreviousAppointments from "../pages/previousAppointments.js";
import Coupon from "../pages/coupon.js";
import Profile from "../pages/profile.js";
import Settings from "../pages/settings.js";
import UpcomingAppointment from "../pages/upcomingAppointment.js";
import MyInvoice from "../pages/myInvoice.js";
import MyGuide from "../pages/myGuide.js";
import BookingCancelled from "../pages/bookingCancelled.js";
import AuthLogin from "../Auth/Login/AuthLogin.jsx";

const HeaderRoutes = ()=>{
    return(
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bookSlot" element={<BookSlot />} />
            <Route path="/bookAppointment" element={<BookAppointment />} />
            <Route path="/previousAppointments/:id" element={<PreviousAppointments />} />
            <Route path="/coupons" element={<Coupon />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/upcomingAppointment/:id" element={<UpcomingAppointment />} />
            <Route path="/myInvoice" element={<MyInvoice />} />
            <Route path="/myGuide" element={<MyGuide />} />
            <Route path="/bookingCancelled" element={<BookingCancelled />} />
            <Route path="/login" element={<AuthLogin />} />
            
        </Routes>
    )
}


export default HeaderRoutes;
import React,{useRef,useEffect,useState} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import file from "../assets/images/Group(1).png"
import download from "../assets/images/download-minimalistic_svgrepo.com.png"
import call from "../assets/images/Frame 583.png"
import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"
import tick from "../assets/images/tick.png"
import coupan from "../assets/images/Group.png"
import faq from "../assets/images/Vector.png"

import { FaChevronDown, FaChevronUp, FaMapMarkerAlt } from 'react-icons/fa';
import { CiLocationOn } from "react-icons/ci";
import { MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import "../assets/css/accordian.css"




import { useParams } from 'react-router-dom';

import { baseUrll } from '../utils/baseUrl';

import { useNavigate } from "react-router-dom";


import "../assets/css/bookSlot.css"
import "../assets/css/previousAppointments.css"

const { Meta } = Card;

const PreviousAppointments = () => {


  const navigate = useNavigate();

  const userId = localStorage.getItem('user');
  const businessId = localStorage.getItem('business');
  const adminId = localStorage.getItem('admin');


  
  if(!userId){
    navigate("/auth")
  }


  const { id } = useParams();

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [pastAppointment,setPastAppointment] = useState("") 
  const [service,setService] = useState("") 




  const sliderRef = useRef(null); // Create a ref for the Slider component

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // Go to previous slide
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // Go to next slide
  };


  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(quantity > 0 ? quantity - 1 : 0);
  };

    //   accordian related functions and use states

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false
  });
  const datePickerRef = useRef(null);

  const [isOpenTime, setIsOpenTime] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const timePickerRef = useRef(null);

  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const fetchLocations = () => {
    // Mock fetching data from a database
    return Promise.resolve([
      { id: 1, name: 'New York' },
      { id: 2, name: 'Los Angeles' },
      { id: 3, name: 'Chicago' },
    ]);
  };

  
  const toggleAccordionLocation = () => {
    setIsOpenLocation(!isOpenLocation);
  };

  const handleRadioChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    const loadLocations = async () => {
      const data = await fetchLocations();
      setLocations(data);
    };
    loadLocations();
  }, []);


  const toggleAccordionTime = () => {
    setIsOpenTime(!isOpenTime);
  };

  useEffect(() => {
    if (isOpenTime && timePickerRef.current) {
      // Trigger the time picker to open
      timePickerRef.current.setFocus();
    }
  }, [isOpenTime]);



  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    if (isOpen && datePickerRef.current) {
      // Trigger the date picker to open
      datePickerRef.current.setFocus();
    }
  }, [isOpen]);



  useEffect(() => {
    const fetchData = async () => {
      try {
       

        const response = await fetch(baseUrl + `services.get/${userId}/${businessId}`,{
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result);
        console.log(result)

         var appoint1  = await result.pastAppointment.find(item => item.userId == userId);
          console.log(appoint1)


          var service = await appoint1.services.find(item => item.id == id );
         console.log(service)
          setPastAppointment(appoint1);
         setService(service)
         if(!service || !appoint1
         ){
          throw error
         }

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts



  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>

      <div className="card3 prevAppointment" style={{justifyContent:"space-between",padding:"20px"}}>


        <div className="sec2">
            <h4></h4>
            <h2>Thanks For Choosing Us !</h2>
            <p>$ {service.price}</p>
            <div className="invoice">
                <img src={file} alt="" />
                <h4>Invoice</h4>
                <img src={download} alt="" />

            </div>
            {/* <div className="rate">
                // <h4>Rate Your Service</h4>
                //  <img src={} alt="" /> 
            </div> */}
        </div>
        <div className="sec3" style={{visibility:"hidden"}}>
          <div className="up">
              
              <img src={share} alt="" />

              <img src={call} alt="" />

              <img src={whatsapp} alt="" />

          </div>

          <div className="down">

            <button>Book Again</button>
            {/* <h5>4.4 (00 reviews) </h5> */}
            
          </div>
        </div>
      </div>  

      <section>
        <div className="main mains" >
            <div className="left leftt" >
                
                        
                    <div className="accordion">
                      <div className="accordion-header" onClick={toggleAccordionLocation}>
                        <div className="accordion-title">
                            <CiLocationOn />
                          <h4>{pastAppointment.location}</h4>
                        </div>
                       
                      </div>
                    </div>


                    <div className="accordion">
                      <div className="accordion-header" onClick={toggleAccordion}>
                        <div className="accordion-title">
                        <MdDateRange />
                        <h4>{pastAppointment.dateTime}</h4>
                        </div>
                      </div>
                   
                    </div>
                        
                    <div className="accordion">
                      <div className="accordion-header" onClick={toggleAccordionTime}>
                        <div className="accordion-title">
                          <IoMdTime />
                          <h4>{service.time.from}</h4>
                        </div>
                      
                      </div>
                    
                    </div>

                    <div className="accordion">
                      <div className="accordion-header">
                        <div className="accordion-title">
                            <div className="contain">
                               <div className="up">
                                     <BsPeople />
                                      <h4>Sam Jackson</h4>
                        
                                 </div>
                                 <div className="down" style={{visibility:"hidden"}}>
                                    <h4>Rate The Professional</h4>
                                 </div>
                            </div>
                         
                         </div>
                            
                      </div>
                    </div>
                       
                    <div className="description">
                        <h2>Add Description</h2>
                        <div className="desc1">
                            Nothing
                        </div>

                    </div>

                    

                
            </div>
            
            <div className="right" >
                <div className="quantity">
                    <div className="q">
                        <div className="qLeft">
                            <h4>Hair</h4>
                            <h2>Hair Cut</h2>
                        </div>

                        <div className="qRight">
                            <p>$ 350</p>

                        </div>
                    </div>

                    <div className="q">
                        <div className="qLeft">
                            <h4>Hair</h4>
                            <h2>Hair Cut</h2>
                        </div>

                        <div className="qRight">
                            <p>$ 350</p>
                           

                        </div>
                    </div>

                    <div className="q">
                        <div className="qLeft">
                            <h4>Hair</h4>
                            <h2>Hair Cut</h2>
                        </div>

                        <div className="qRight">
                            <p>$ 350</p>
                            

                        </div>
                    </div>

                    <div className="q">
                        <div className="qLeft">
                            <h4>Hair</h4>
                            <h2>Hair Cut</h2>
                        </div>

                        <div className="qRight">
                            <p>$ 350</p>
                           

                        </div>
                    </div>
                </div>

                            
               
               <div className="bill">
                  <h3>Payment Summary</h3>
                  <div className="bill1">
                     <div className="bill11">
                        <h2>Taxes and fees</h2>
                        <h3>$ {pastAppointment.taxAndFee}</h3>
                     </div>

                     <div className="bill11">
                        <h2>Service Charges</h2>
                        <h3>$ {pastAppointment.serviceCharge}</h3>
                     </div>

                     
                  </div>
                  <div className="bill2">
                    <h2>Total</h2>
                    <p>$ {service.price}</p>
                  </div>
               </div>
            </div>
        </div>
            
    </section>

    <section>
        <div className="afterCare">

            <h1>After Care Tips</h1>
            <div className="row">
                <img src={tick} alt="" />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque, nesciunt!</p>
            </div>

            <div className="row">
                <img src={tick} alt="" />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque, nesciunt!</p>
            </div>

            <div className="row">
                <img src={tick} alt="" />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque, nesciunt!</p>
            </div>

            <div className="row">
                <img src={tick} alt="" />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque, nesciunt!</p>
            </div>

            <div className="row">
                <img src={tick} alt="" />
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque, nesciunt!</p>
            </div>

            


        </div>


        <div className="faqContainer">

            <div className="faq">
                <img src={faq} alt="" />
                <h2>Frequently Asked Questions</h2>
            </div>


            <div className="descript">
            <div className="accordion">
                  <div className="accordion-header" onClick={toggleAccordionLocation}>
                    <div className="accordion-title">
                        <CiLocationOn />
                      <h4>Select Location</h4>
                    </div>
                    <div className="accordion-icon">
                      {isOpenLocation ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {isOpenLocation && (
                    <div className="accordion-content">
                        <div className="radios">
                                
                            {locations.map((location) => (
                              <label key={location.id} className="radio-label">
                              <input
                                type="radio"
                                name="location"
                                value={location.name}
                                checked={selectedLocation === location.name}
                                onChange={handleRadioChange}
                                className="custom-radio"
                              />
                              <span className="custom-radio-span"></span>
                              <FaMapMarkerAlt className="location-icon" />
                              {location.name}
                            </label>
                            ))}
                                
                        </div>

                    </div>
                  )}
                </div>

                <div className="accordion">
                  <div className="accordion-header" onClick={toggleAccordionLocation}>
                    <div className="accordion-title">
                        <CiLocationOn />
                      <h4>Select Location</h4>
                    </div>
                    <div className="accordion-icon">
                      {isOpenLocation ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {isOpenLocation && (
                    <div className="accordion-content">
                        <div className="radios">
                                
                            {locations.map((location) => (
                              <label key={location.id} className="radio-label">
                              <input
                                type="radio"
                                name="location"
                                value={location.name}
                                checked={selectedLocation === location.name}
                                onChange={handleRadioChange}
                                className="custom-radio"
                              />
                              <span className="custom-radio-span"></span>
                              <FaMapMarkerAlt className="location-icon" />
                              {location.name}
                            </label>
                            ))}
                                
                        </div>

                    </div>
                  )}
                </div>


                <div className="accordion">
                  <div className="accordion-header" onClick={toggleAccordionLocation}>
                    <div className="accordion-title">
                        <CiLocationOn />
                      <h4>Select Location</h4>
                    </div>
                    <div className="accordion-icon">
                      {isOpenLocation ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {isOpenLocation && (
                    <div className="accordion-content">
                        <div className="radios">
                                
                            {locations.map((location) => (
                              <label key={location.id} className="radio-label">
                              <input
                                type="radio"
                                name="location"
                                value={location.name}
                                checked={selectedLocation === location.name}
                                onChange={handleRadioChange}
                                className="custom-radio"
                              />
                              <span className="custom-radio-span"></span>
                              <FaMapMarkerAlt className="location-icon" />
                              {location.name}
                            </label>
                            ))}
                                
                        </div>

                    </div>
                  )}
                </div>
            </div>


        </div>


    </section>

      <Banner/>

                
    </>
  );
};

export default PreviousAppointments;
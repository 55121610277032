import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import app from "../../utils/fibaseconfig.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from "./Login";

const AuthLogin = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    const storedUser1 = localStorage.getItem('user');
    const storedUser2 = localStorage.getItem('admin');
    const storedUser3 = localStorage.getItem('business');
    
    if (storedUser1 && storedUser2 && storedUser3) {
      navigate("/index");
    } 
    // else {
    //   onAuthStateChanged(auth, (user) => {
    //     if (user) {
    //       localStorage.setItem('user', JSON.stringify(user));
    //       navigate("/home");
    //     } else {
    //       navigate("/login");
    //     }
    //   });
    // }
  }, [auth, navigate]);

  return (
    <div>
      <Login />
    </div>
  );
};

export default AuthLogin;

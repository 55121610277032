/* eslint-disable react/prop-types */
import classNames from "classnames";

const Input = ({
    className,
    type,
    placeholder,
    name,
    value,
    defaultValue,
    required,
    onChange,
    ...props
}) => {
    return (
        <input
            type={type}
            className={classNames(
                "border-none shadow-md placeholder:text-[#575757] text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                className
            )}
            placeholder={placeholder}
            name={name}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            required={required}
            {...props}
        />
    );
};

export { Input };

import React,{useRef,useState,useEffect} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"

import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"


import BookCard from '../components/bookCard.js';


import { baseUrll } from '../utils/baseUrl.js';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';


import "../assets/css/bookSlot.css"
const { Meta } = Card;

const BookSlot = () => {
  var userId = 1;
  var businessId = 1;

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")

  const [business,setBusiness] = useState("")

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
      

        const response = await fetch(baseUrl + `businesses.get/${businessId}`,{
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        // console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result);
        setBusiness(result.business)
        console.log(result)

        //  var appoint1  = await result.upcomingAppointment.find(item => item.userId === userId);
        //   console.log(appoint1)

        //  setUpcomAppointment(appoint1);

        //  var appoint2  = result.pastAppointment.find(item => item.userId === userId);

        //  setPrevAppointment(appoint2);

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  





  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [width, setWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(3);
  var slid = 4; 
  if(width<1200){
    slid = 2
  }
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: slid,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // Go to previous slide
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // Go to next slide
  };


  // total price 

  const [totalPrice, setTotalPrice] = useState(0);
  const [listItem, setListItem] = useState([])

    const updateTotalPrice = (priceChange,item) => {
      if(item.quantity!= 0){
        const idx = listItem.findIndex(element => element.id === item.id);
        if(idx == -1){
          listItem.push(item)
        }
        else{
          listItem[idx].quantity = item.quantity
        }
        // console.log(listItem)
      }
      else{
        const idx = listItem.findIndex(element => element.id === item.id);
        if(idx != -1){
          listItem.splice(idx, 1);
    
        }
      }
      

      // sendData();
      console.log(listItem)
    
    setTotalPrice((prevTotal) => prevTotal + priceChange);
  };

  // const sendData = async () => {
  //   try {
  //     const response = await fetch(baseUrl+'cart.add', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"

  //       },
  //       body: JSON.stringify({
  //         userId :userId,
  //         businessId:businessId,
  //         services:[
  //           ...listItem
  //         ]
  //         })
  //     });

  //     const result = await response.json();
  //     console.log('Success:', result);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <>

      <div className="card3 bookSlot">

        <div className="sec1">
            <img src={baseUrl + business.image} alt="" />
        </div>

        <div className="sec2">
            <h4>{business.businessType}</h4>
            <h2>{business.businessName}</h2>
            <p>{business.aboutBusiness}</p>
            <a href={ business.website}>{business.website}</a>
        </div>
        <div className="sec3">
          <div className="up">
          <a href={ business.website}><img src={web} alt="" /></a>
        
          <a href={ business.socialMediaLink[0]}><img src={insta} alt="" /></a>

          <a href={  business.socialMediaLink[1]}><img src={face} alt="" /></a>

          <a href={ business.website}> <img src={whatsapp} alt="" /></a>

              {/* <img src={share} alt="" /> */}
          </div>

          <div className="down" style={{visibility:"hidden"}}>

            <img src={star} alt="" />
            <h5>4.4 (00 reviews) </h5>
            
          </div>
        </div>
      </div>  

      <section style={{display:"none"}}>
          
          <div className="carousel-navigation">
            <img src={prev} className="prev-button" onClick={goToPrevSlide}/>
            <img src={next} className="next-button" onClick={goToNextSlide}/>
          
          </div>

          <div className="carousel-container">
            <Slider style={{padding: "10px",}} ref={sliderRef} {...settings}>
              <div>

                <div className='bar' >
                    <h2>Hair Cut</h2>
                </div>
              
              </div>
              


              <div >

              <div className='bar' >
                    <h2>Spa</h2>
                </div>
              </div>


              <div >
              <div className='bar' >
                    <h2>Facial</h2>
                </div>
              </div>


              <div >
              <div className='bar' >
                    <h2>Skincare</h2>
                </div>
              </div>

              <div >
              <div className='bar' >
                    <h2>Massage</h2>
                </div>
              </div>
             
              {/* Add more slides as needed */}
            </Slider>
            {/* Navigation buttons */}
        
          </div>
      </section>


      <section>
     
      <h3>Services</h3>
        {totalPrice!=0?
         <div className="priceHeader">
         <h1>Total Price: ${totalPrice}</h1>

         <Link to="/index/bookAppointment"><button>Book Now</button></Link>

         </div>:

         ""
      }
       
       

        <div className="card4">
        {business?.services?.map((item, index) => (
        <BookCard key={index} item={item} updateTotalPrice={updateTotalPrice} />
          
        ))}


            

        </div>

        
        
      </section>


      <Banner/>

                
    </>
  );
};

export default BookSlot;
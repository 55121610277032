import React,{useRef,useEffect,useState} from 'react';
import { Button, Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling
import { Input } from "antd";

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import file from "../assets/images/Group(1).png"
import download from "../assets/images/download-minimalistic_svgrepo.com.png"
import call from "../assets/images/Frame 583.png"
import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"
import tick from "../assets/images/tick.png"
import coupan from "../assets/images/Group.png"
import faq from "../assets/images/Vector.png"
import couponBg from "../assets/images/coupanBg.png"


import { FaChevronDown, FaChevronUp, FaCopy, FaMapMarkerAlt } from 'react-icons/fa';
import { CiCamera, CiLocationOn } from "react-icons/ci";
import { MdCall, MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import "../assets/css/accordian.css"
import "../assets/css/settings.css"



const { Meta } = Card;

const Settings = () => {
  
  return (
    <>
       <div className="about">
            <h1>About Company</h1>
            <p> t Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus esse, nisi quasi ipsum dicta delectus numquam impedit, deleniti laudantium deserunt, perferendis beatae laborum possimus. Quas sequi culpa quam vel eius exercitationem autem neque, laudantium vitae similique sed eveniet accusantium cum numquam accusamus, obcaecati, incidunt cumque veniam cupiditate eaque! Dolorum modi atque cum assumenda omnis non corporis provident labore expedita, voluptatum voluptate ex quis ad amet ut, officiis, quos sit libero enim dignissimos autem facere voluptatem. Molestias veniam blanditiis ab autem aut accusantium consectetur, nihil quod. Aspernatur enim cum repellendus recusandae dignissimos culpa debitis corrupti voluptate architecto. Ab quaerat assumenda hic laudantium temporibus officia, dolor atque eligendi ea deleniti animi minima consequatur delectus ducimus, ipsam tenetur! Quidem eligendi officiis inventore magnam corporis provident, voluptatibus sapiente tempora eaque reprehenderit blanditiis laudantium quae, dolores, porro vero. Corporis molestiae eveniet, vero voluptates odio ipsam eos excepturi at vitae porro officia accusantium maxime architecto recusandae quasi earum, iusto id sequi ab alias. Veritatis, mollitia voluptas! Odio dolores recusandae incidunt et rerum alias modi sit pariatur, ad a molestiae ullam autem aspernatur unde, amet libero, doloremque optio rem ex ipsa nemo eligendi adipisci voluptas. Vel sequi consequuntur eaque et tenetur molestias a neque culpa, laudantium iusto!</p>
        </div> 
        
        <div className="settings">
            <div className="r1">
                <h1>Terms And Conditions</h1>
               <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa, totam.</p>
               <div className="inv">
                    <img src={file} alt="" />
                    <div className="invText">
                        <p>Download Document to verify Terms </p>
                        <p>& Conditions</p>
                    </div>
                    <img src={download} alt="" />
               </div>
            </div> 

            <div className="r1">
                <h1>Terms And Conditions</h1>
               <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa, totam.</p>
               <div className="inv">
                    <img src={file} alt="" />
                    <div className="invText">
                        <p>Download Document to verify Terms </p>
                        <p>& Conditions</p>
                    </div>
                    <img src={download} alt="" />
               </div>
            </div> 
        </div>
        

      {/* <Banner/> */}

                
    </>
  );
};

export default Settings;
import React,{useRef,useState,useEffect} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"


import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"

import coupan from "../assets/images/Group.png"

import { FaChevronDown, FaChevronUp, FaMapMarkerAlt } from 'react-icons/fa';
import { CiLocationOn } from "react-icons/ci";
import { MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../assets/css/accordian.css"


import "../assets/css/bookAppointment.css"

import { useNavigate } from "react-router-dom";
import { baseUrll } from '../utils/baseUrl';

const { Meta } = Card;

const BookAppointment = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem('user');
  const businessId = localStorage.getItem('business');
  const adminId = localStorage.getItem('admin');
  console.log("safda",typeof(userId))
  // alert(businessId)

  
  if(!userId){
    navigate("/auth")
  }

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")


  var totalCartPrice = 0 

  // const [upcomAppointment,setUpcomAppointment] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `cart.getByUser/${userId}`,{
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        // console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result.cartItems);
        result.cartItems?.map((item, index) =>{ 
            console.log(item.service.price)
           return totalCartPrice = totalCartPrice + parseInt(item.service.price)
      })
        console.log(totalCartPrice)
        // setTotalCart(totalCartPrice)

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const AddData = async () => {
    try {
      // var userId = 1;
      // var businessId = 1;
      // console.log("ids",typeof(userId),typeof(businessId),typeof(serviceId),quantityVar)
      const obj =
        {
          "userId": parseInt(userId),
          "adminId": parseInt(adminId),
          "location": inputValue1,
          "dateTime": "2024-07-03 13:00",
          "description": inputValue2

      }
        
      const response = await fetch(baseUrl + `appointment.book`,{
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
        },
        body: JSON.stringify(obj)
          
      });



      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("dasfafs",result)
      alert(result.message)
      navigate("/index")
      // console.log(result.upcomingAppointmen);
      // for(let i = 0 ; i < result.)
      // setData(result);
        
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sliderRef = useRef(null); // Create a ref for the Slider component

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // Go to previous slide
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // Go to next slide
  };


  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(quantity > 0 ? quantity - 1 : 0);
  };

    //   accordian related functions and use states

  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false
  });
  const datePickerRef = useRef(null);

  const [isOpenTime, setIsOpenTime] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const timePickerRef = useRef(null);

  const [isOpenLocation, setIsOpenLocation] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');

  const fetchLocations = () => {
    // Mock fetching data from a database
    return Promise.resolve([
      { id: 1, name: 'New York' },
      { id: 2, name: 'Los Angeles' },
      { id: 3, name: 'Chicago' },
    ]);
  };

  
  const toggleAccordionLocation = () => {
    setIsOpenLocation(!isOpenLocation);
  };

  const handleRadioChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    const loadLocations = async () => {
      const data = await fetchLocations();
      setLocations(data);
    };
    loadLocations();
  }, []);


  const toggleAccordionTime = () => {
    setIsOpenTime(!isOpenTime);
  };

  useEffect(() => {
    if (isOpenTime && timePickerRef.current) {
      // Trigger the time picker to open
      timePickerRef.current.setFocus();
    }
  }, [isOpenTime]);



  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });
  };

  useEffect(() => {
    if (isOpen && datePickerRef.current) {
      // Trigger the date picker to open
      datePickerRef.current.setFocus();
    }
  }, [isOpen]);



  const [isOpenCoupan, setIsOpenCoupan] = useState(false);

  const toggleAccordionCoupan = () => {
    setIsOpenCoupan(!isOpenCoupan);
  };



  const [inputValue1, setInputValue1] = useState('');

  // Function to handle input change
  const handleInputChange1 = (event) => {
    setInputValue1(event.target.value);
  };

  const [inputValue2, setInputValue2] = useState('');

  // Function to handle input change
  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>


        <section className='bookAppointment'>
            <div className="main">
                <div className="left lefts">
                        
                        <div className="accordion">
                          <div className="accordion-header" >
                            <div className="accordion-title">
                                <CiLocationOn />
                              <h4><input
                                type="text"
                                placeholder='Enter Location'
                                value={inputValue1}
                                onChange={handleInputChange1}
                              /></h4>
                            </div>
                            {/* <div className="accordion-icon">
                              {isOpenLocation ? <FaChevronUp /> : <FaChevronDown />}
                            </div> */}
                          </div>
                          {isOpenLocation && (
                            <div className="accordion-content">
                                <div className="radios">
                                
                                    {locations.map((location) => (
                                      <label key={location.id} className="radio-label">
                                      <input
                                        type="radio"
                                        name="location"
                                        value={location.name}
                                        checked={selectedLocation === location.name}
                                        onChange={handleRadioChange}
                                        className="custom-radio"
                                      />
                                      <span className="custom-radio-span"></span>
                                      <FaMapMarkerAlt className="location-icon" />
                                      {location.name}
                                    </label>
                                    ))}
                                
                                </div>

                            </div>
                          )}
                        </div>


                        <div className="accordion">
                          <div className="accordion-header" onClick={toggleAccordion}>
                            <div className="accordion-title">
                            <MdDateRange />
                            <h4>Select Date</h4>
                            </div>
                            <div className="accordion-icon">
                              {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                          </div>
                          {isOpen && (
                            <div className="accordion-content">
                              <div className="date-picker">
                                <DatePicker ref={datePickerRef} selected={startDate} onChange={(date) => setStartDate(date)} />
                              </div>
                     
                            </div>
                          )}
                        </div>
                        
                        <div className="accordion">
                          <div className="accordion-header" onClick={toggleAccordionTime}>
                            <div className="accordion-title">
                              <IoMdTime />
                              <h4>Select Time</h4>
                            </div>
                            <div className="accordion-icon">
                              {isOpenTime ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                          </div>
                          {isOpenTime && (
                            <div className="accordion-content">
                              <div className="time-picker">
                                <DatePicker
                                  selected={startTime}
                                  onChange={(time) => setStartTime(time)}
                                  ref={timePickerRef}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="accordion">
                          <div className="accordion-header">
                            <div className="accordion-title">
                                <BsPeople />
                              <h4>Person serving this services allot you soon</h4>
                            </div>
                            
                          </div>
                         
                        </div>
                       
                        <div className="description">
                            <h2>Add Description</h2>
                          
                            <div className="desc1">
                            <input
                                type="text"
                                placeholder='Enter Description'
                                value={inputValue2}
                                onChange={handleInputChange2}
                              />
                            </div>

                        </div>

                        <div className="cancel">
                            <h2>CANCELLATION POLICY</h2>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt illum ex distinctio eligendi delectus suscipit sapiente, quibusdam fugit voluptatum quaerat aut consequatur rem maiores. Assumenda!</p>
                            <a href="">Learn More</a>
                        </div>
                        
                      
                </div>
            
                <div className="right">
                    <div className="quantity" style={{height:"300px",overflowY:"scroll"}}>
                    {data?.map((item, index) => (

                        <div className="q">
                            <div className="qLeft">
                                <h4>{item.service.serviceType}</h4>
                                <h2>{item.service.serviceName}</h2>
                            </div>

                            <div className="qRight">
                                {/* {()=>totalCartPrice += parseInt(item.service.price)} */}
                                <p>{item.service.price}</p>
                                <div className="quantity-container">
                                  <button onClick={decreaseQuantity} className="quantity-button">
                                    -
                                  </button>
                                  <span className="quantity-display">{item.qty}</span>
                                  <button onClick={increaseQuantity} className="quantity-button">
                                    +
                                  </button>
                                </div>

                            </div>
                        </div>

                    ))}   
                    </div>

                            
                   <div className="coupan">
                   <div className="accordion">
                      <div className="accordion-header" onClick={toggleAccordionCoupan}>
                        <div className="accordion-title">
                          <img src={coupan} alt="" />
                          <div className="title1">
                              <h4>Coupans And Offers</h4>
                              <p>Save on this booking</p>
                        
                          </div>
                        </div>
                        <div className="accordion-icon">
                          {isOpenCoupan ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                      </div>
                      {isOpenCoupan && (
                        <div className="accordion-content">
                          <div className="cp">
                            <div className="cpUp">
                                <div className="cpUpLeft"> 
                                    <h2>5% OFF</h2>
                                    <h5>For Whole order</h5>

                                </div>
                                <div className="cpUpRight">
                                    <button>Apply</button>
                                </div>

                            </div>
                            <div className="cpDown">
                                <h3>Code: 329423424h324</h3>
                                <FaRegCopy />
                            </div>
                          </div>
                          
                        </div>
                      )}
                    </div>
                   </div>

                   <div className="bill">
                      <h3>Bill</h3>
                      <div className="bill1">
                         <div className="bill11">
                            <h2>Taxes and fees</h2>
                            <h3>15$</h3>
                         </div>

                         <div className="bill11">
                            <h2>Taxes and fees</h2>
                            <h3>15$</h3>
                         </div>

                         <div className="bill11">
                            <h2>Taxes and fees</h2>
                            <h3>15$</h3>
                         </div>
                      </div>
                      <div className="bill2">
                        <h2>Total</h2>
                        <p>${totalCartPrice}</p>
                      </div>
                   </div>

                   <button onClick={AddData} style={{width:"140px",height:"60px",backgroundColor:"green",color:"white",margin:"20px"}}>Proceed To Book</button>
                </div>


            </div>
            
        </section>
    
      <Banner/>

                
    </>
  );
};

export default BookAppointment;
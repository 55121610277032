/* eslint-disable react/prop-types */
const sizes = {
    "5xl": "text-3xl font-bold md:text-[28px] sm:text-[26px]",
    xs: "text-[13px] font-normal",
    lg: "text-base font-normal",
    "6xl": "text-[45px] font-bold md:text-[41px] sm:text-[35px]",
    s: "text-sm font-normal",
    "2xl": "text-lg font-normal",
    "3xl": "text-xl font-normal",
    "4xl": "text-[25px] font-bold md:text-[23px] sm:text-[21px]",
    xl: "text-[17px] font-normal",
    md: "text-[15px] font-normal",
};

const Text = ({ children, className = "", as, size = "s", ...restProps }) => {
    const Component = as || "p";
    return (
        <Component
            className={`text-gray-700  ${className} ${sizes[size]}`}
            {...restProps}
        >
            {children}
        </Component>
    );
};

export { Text };

import React from "react";
import Home from "../pages/home.js"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "../assets/css/footerComp.css";

import { Layout, Menu } from 'antd';
import logo from "../assets/images/logo.png"
import "../assets/css/home.css"
import banner from "../assets/images/bannerFooter.png"
const Banner = ()=>{
    return(
       <>
         <section>
          <div className="bannerContainer">
          <div className="banner"
            >
                {/* <img src={banner} alt="" /> */}
            </div>
          </div>
         
        </section>
       </>
    )
}


export default Banner;
import React from "react";
import Home from "../pages/home.js"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "../assets/css/footerComp.css";

import { Layout, Menu } from 'antd';
import logo from "../assets/images/logo.png"


import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"

import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"



const FooterComp = ()=>{
    return(
       <>
        <div className="foot foot1">
            

                <div className="sec1">

<div className="logo" >
        <img src={logo} alt="" />
</div>

<div className="desc">
    <p >Lorem ipsum dolor sit amet, consectetur
    adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magnaaliqua.</p>   
</div>
<div className="links">
    <h5>Social Links</h5>
    <div className="icons">
        {/* images her */}
                    <img src={insta} alt="" />
                <img src={face} alt="" />
         {/* <img src={mail} a/lt="" /> */}
                    <img src={whatsapp} alt="" />
                    </div>
                </div>

                </div>
                <div className="sec2">
                    <h4>company</h4>

   
                   <div className="menu">

          <Link to="/" ><p>About</p></Link>
    

    
          <Link to="/" ><p>Terms & Conditions</p></Link>

          <Link to="/" ><p>Privacy Policy</p></Link>
    


          <Link to="/" ><p>Contact Us</p></Link>

        </div>
  

                </div>


            <div className="sec3">
                <h4>Join Us</h4>

               
                <div className="inpt">
                    <input type="email" placeholder="Enter your Email" />
                    <input type="number" placeholder="Enter your contact number" />
               
                </div>
                
               <div className="btn">
                <button>Submit</button>
               </div>
            </div>
        </div>

        <div className="foot foots">
            
        <div className="sec3">
                <h4>Join Us</h4>

   
                <div className="inpt">
                    <input type="email" placeholder="Enter your Email" />
                    <input type="number" placeholder="Enter your contact number" />
   
                </div>
    
               <div className="btn">
                <button>Submit</button>
               </div>
            </div>
            
            <div className="sec1">

<div className="logo" >
    <img src={logo} alt="" />
</div>

<div className="desc">
<p >Lorem ipsum dolor sit amet, consectetur
adipiscing elit, sed do eiusmod tempor 
incididunt ut labore et dolore magnaaliqua.</p>   
</div>
<div className="links">
<h5>Social Links</h5>
<div className="icons">
    {/* images her */}
                <img src={insta} alt="" />
            <img src={face} alt="" />
     {/* <img src={mail} a/lt="" /> */}
                <img src={whatsapp} alt="" />
                </div>
            </div>

            </div>
            <div className="sec2">
                <h4>company</h4>


               <div className="menu">

      <Link to="/" ><p>About</p></Link>



      <Link to="/" ><p>Terms & Conditions</p></Link>

      <Link to="/" ><p>Privacy Policy</p></Link>



      <Link to="/" ><p>Contact Us</p></Link>

    </div>


            </div>


      
    </div>

        
       </>
    )
}


export default FooterComp;
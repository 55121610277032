import { useEffect, useState,useRef } from "react";
import { Button, Img, Input, Text } from "../../components";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import app from "../../utils/fibaseconfig.js";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import loginImg from "../../assets/images/loginImg.png"
import { baseUrll } from "../../utils/baseUrl.js";
import { useParams } from 'react-router-dom';

const Login = () => {
  const { adminId,businessId } = useParams();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("Enter Otp");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);


  const auth = getAuth(app);
  const navigate = useNavigate();


  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")


  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "normal",
        callback: (response) => {
          console.log(response);
        },
        "expired-callback": () => {
          console.log("expired");
        },
      }
    );
  }, [auth]);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSendOtp = async () => {
    try {
      const formattedphoneNumber = `+${phoneNumber.replace(/\D/g, "")}`;
      const confirmation = await signInWithPhoneNumber(
        auth,
        formattedphoneNumber,
        window.recaptchaVerifier
      );
      setConfirmationResult(confirmation);
      setOtpSent(true);
      console.log("confffi",confirmation)
      alert("otp Sent")
      toast.success("OTP sent successfully");
    } catch (error) {
      console.log("hjafdasfa")
      console.error(error);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const result = await confirmationResult.confirm(otp);
      console.log(result)

          try {
            // var userId = 1;
            var businessId = 1;
            let newNumber = phoneNumber.substring(2);
            const response = await fetch(baseUrl + `user.find?adminId=${adminId}&phone=${newNumber}`,{

              method: 'GET', 
              headers: {
                'Content-Type': 'application/json',
                ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
              },
            });



            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            // console.log(result.upcomingAppointmen);
            // for(let i = 0 ; i < result.)
            setData(result);
            console.log(result)

            if(result.status == true){
              
              localStorage.setItem('user', result.id);
              localStorage.setItem("admin",adminId);
              // localStorage.setItem("business",adminId);
              
              const response1 = await fetch(baseUrl + `user.get/${result.id}`,{

                method: 'GET', 
                headers: {
                  'Content-Type': 'application/json',
                  ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
                },
              });



              if (!response1.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              const result1 = await response1.json();
              // console.log(result.upcomingAppointmen);
              // for(let i = 0 ; i < result.)
              // setData(result);
              // console.log(result)

              console.log(result1)
              localStorage.setItem("business",businessId);
              

              navigate("/index/");

            }

            //  var appoint1  = await result.upcomingAppointment.find(item => item.userId === userId);
            //   console.log(appoint1)

            //  setUpcomAppointment(appoint1);

            //  var appoint2  = result.pastAppointment.find(item => item.userId === userId);

            //  setPrevAppointment(appoint2);

          } catch (error) {
            setError(error.message);
          } finally {
            setLoading(false);
          }


      // const user = result.user;
      // localStorage.setItem('user', JSON.stringify(user));
      setOtp("");
      toast.success("Login successful");
    } catch (error) {
      console.error(error);
    }
  };
  const wrapperRef = useRef(null); 
  const handleClick = () => {
    if (wrapperRef.current) {
      const input = wrapperRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | User</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="md:h-screen flex flex-wrap-reverse w-full items-start justify-between bg-white-A700 mb-4 lg:mb-0">
        <div className="w-full md:w-1/2 md:h-screen flex flex-col justify-center items-center bg-white-A700 max-w-[60vw] mx-auto">
          <Text
            size="5xl"
            as="p"
            className="uppercase font-ubuntu !text-primary font-bold my-4"
          >
            log in
          </Text>
          <div>
            {!otpSent ? <div id="recaptcha-container"></div> : null}
            <div className="w-full mx-auto md:max-w-[400px]">
             
            <div ref={wrapperRef} onClick={handleClick} style={{ width: '100%' }}>
              <PhoneInput
                // inputRef={phoneInputRef}
                country={"in"}
                value={phoneNumber}
                onChange={setPhoneNumber}
                inputStyle={{
                  width: "100%",
                  height: "48px",
                  fontSize: "16px",
                  color: "#000",
                  borderRadius: "8px",
                  padding: "10px",
                  outlineColor:"black"
                }}
                containerStyle={{
                  width: "100%",
                  outlineColor:"black"
                }}
                inputProps={{
                  name: "contact_number",
                  required: true,
                }}
                // onClick={handleFocus}
              />

              </div>
             
              <div id="recaptcha-container"></div>
              <Text
                size="lg"
                as="p"
                onClick={handleSendOtp}
                className="self-end capitalize !text-primary underline underline-offset-4 text-right sm:my-4 my-6 cursor-pointer"
              >
                generate otp
              </Text>
              <Input
                color="blue_gray_900_01"
                variant="outline"
                shape="round"
                value={otp}
                onChange={handleOtpChange}
                type="number"
                name="otp"
                placeholder={otp}
                className="self-stretch mx-auto w-[60vw] md:max-w-[386px] font-ubuntu uppercase sm:pr-5 sm:my-4 my-6"
              />
              <Button
                color="blue_gray900_01"
                size="lg"
                style = {{backgroundColor:"#1B3B36",color:"white"}}
                shape="round"
                onClick={handleOtpSubmit}
                className="bg-primary font-ubuntu flex justify-center items-center w-52 mx-auto text-white-A700 rounded py-2 uppercase"
              >
                login
              </Button>
            </div>
          </div>
         
        </div>
        <div style={{backgroundColor:"#1B3B36"}} className="w-full md:w-1/2 bg-primary flex justify-center items-center min-h-[50vh] md:h-screen">
          <Img
            src={loginImg}
            alt="Login Illustration"
            className={"w-auto"}
          />
        </div>
      </div>
    </>
  );
};

export default Login;
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAN-faCdGcZEcL26WuYomVXP4HqWv8y22g",
  authDomain: "tattoo-a994a.firebaseapp.com",
  databaseURL: "https://tattoo-a994a-default-rtdb.firebaseio.com",
  projectId: "tattoo-a994a",
  storageBucket: "tattoo-a994a.appspot.com",
  messagingSenderId: "874119750866",
  appId: "1:874119750866:web:a46831b6a3cde1df00790b"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBGclNXMBn6ijg1ic3QfMwYAUPFyfw0d34",
//   authDomain: "quism-93eb3.firebaseapp.com",
//   databaseURL: "https://tattoo-a994a-default-rtdb.firebaseio.com",
//   projectId: "quism-93eb3",
//   storageBucket: "quism-93eb3.appspot.com",
//   messagingSenderId: "135880734070",
//   appId: "1:135880734070:web:2be713f9ad878328fcb657"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
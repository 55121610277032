import React,{useRef,useEffect,useState} from 'react';
import { Button, Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling
import { Input } from "antd";

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import file from "../assets/images/Group(1).png"
import download from "../assets/images/download-minimalistic_svgrepo.com.png"
import call from "../assets/images/Frame 583.png"
import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"
import tick from "../assets/images/tick.png"
import coupan from "../assets/images/Group.png"
import faq from "../assets/images/Vector.png"
import couponBg from "../assets/images/coupanBg.png"


import { FaChevronDown, FaChevronUp, FaCopy, FaMapMarkerAlt } from 'react-icons/fa';
import { CiCamera, CiLocationOn } from "react-icons/ci";
import { MdCall, MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import "../assets/css/accordian.css"

import "../assets/css/profile.css"
import { baseUrll } from '../utils/baseUrl';


import { useNavigate } from "react-router-dom";


const { Meta } = Card;

const Profile = () => {


    const navigate = useNavigate();

    const userId = localStorage.getItem('user');
    const businessId = localStorage.getItem('business');
    const adminId = localStorage.getItem('admin');
    // alert(businessId)


    if(!userId){
      navigate("/auth")
    }

    const baseUrl = baseUrll;
    // fetch api
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
      
    const [upcomAppointment,setUpcomAppointment] = useState("") 
      
    const [prevAppointment,setPrevAppointment] = useState("")
      
    // const [upcomAppointment,setUpcomAppointment] = useState("")
    useEffect(() => {
      const fetchData = async () => {
        try {
    
      
          const response = await fetch(baseUrl + `user.get/${userId}`,{
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
            },
          });
      
      
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
        //   console.log(result.upcomingAppointmen);
          // for(let i = 0 ; i < result.)
          setData(result.user);
        //   console.log(result)
      
        //    var appoint1  = await result.upcomingAppointment.find(item => item.userId === userId);
        //     console.log(appoint1)
      
        //    setUpcomAppointment(appoint1);
      
        //    var appoint2  = result.pastAppointment.find(item => item.userId === userId);
      
        //    setPrevAppointment(appoint2);
      
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
      
      fetchData();
    }, []); // Empty dependency array means this effect runs once when the component mounts
      
    
    if (loading) {
        return <div>Loading...</div>;
      }
    
      
  return (
    <>
        <section>
        <div className="profiles">
            
            

            <div className="row">
                <div className="left" style={{width:"80%"}}>
                    <div className="profileImg">
                        <img src="" alt="" />
                        <div className="edit">

                            <CiCamera/>
                        </div>
                    </div>

                    <div className="name">
                        <h2>{data.name}</h2>
                    </div>

                    <div className="details">
                        <div className="col">
                            <div className="row1">
                                <MdCall/>
                                <h4>{data.phone}</h4>
                            </div>

                            <div className="row1">
                                <CiLocationOn/>
                                <h4>{data.email}</h4>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="right" style={{display:"none"}}>
                    <div className="inp">
                       <input type="text" placeholder='Name' />
                    
                    </div>

                    <div className="inp">
                       <input type="text" placeholder='Email' />
                    
                    </div>


                    <div className="inp">
                       <input type="text" placeholder='User Name' />
                    
                    </div>


                    <div className="inp">
                       <input type="text" placeholder='Contact Details' />
                    
                    </div>


                    <div className="inp">
                       <input type="text" placeholder='Address Details' />
                    
                    </div>

                    <div className="btn">
                    <button>Update</button>
                    
                    </div>
                        
                
                </div>
            </div>

        </div>
        </section>
        
        



      {/* <Banner/> */}

                
    </>
  );
};

export default Profile;
import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Layout, Menu } from 'antd';
import 'antd/dist/reset.css';  // Import Ant Design styles by resetting them to avoid conflicts
import './App.css';  // Import custom styles
import { BrowserRouter as Router, Route, Routes, Link,Navigate } from 'react-router-dom';
import HeaderRoutes from './routes/routes';
import HeaderComp from './components/header';
import Index from './pages/index.js';
 

import FooterComp from './components/footer';
import Home from "./pages/home";
import Login from './Auth/Login/Login.jsx';
import AuthLogin from './Auth/Login/AuthLogin.jsx';
const { Header, Footer, Content } = Layout;

function App() {
  return (
    <Router>

    <Routes>
        <Route path="/" element={<Navigate to="/auth/:adminId/:businessId" />} />
        <Route path="/auth" element={<AuthLogin />} />
        <Route path="/auth/:adminId/:businessId" element={<AuthLogin />} />
        <Route path="/index/*" element={<Index />} />
        
            
    </Routes>
  </Router>
  );
}

export default App;

// import logo from './logo.svg';
// import './App.css';
import React from 'react';
import { Layout, Menu } from 'antd';
import 'antd/dist/reset.css';  // Import Ant Design styles by resetting them to avoid conflicts
// import './App.css';  // Import custom styles
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HeaderRoutes from '../routes/routes';
import HeaderComp from '../components/header';
 
 

import FooterComp from '../components/footer';
// import Home from "./pages/home";
const { Header, Footer, Content } = Layout;

function Index() {
  return (

    <Layout className="layout">
  
  
    <Header style={{padding:0,backgroundColor:"white"}}>
      <HeaderComp/>
    </Header>
  
  
    <Content style={{ backgroundColor:"white" }}>
      <div className="site-layout-content">
        <HeaderRoutes/>
      </div>
    </Content>

    <Footer style={{ textAlign: 'center',backgroundColor:"white" }}>
       <FooterComp/>
    </Footer>
  
  </Layout>
  );
}

export default Index;

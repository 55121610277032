import React,{useRef,useState,useEffect} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling
import "../assets/css/home.css"
import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import {  baseUrll } from '../utils/baseUrl';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const { Meta } = Card;


const Home = () => {

  const navigate = useNavigate();

  const userId = localStorage.getItem('user');
  const businessId = localStorage.getItem('business');
  const adminId = localStorage.getItem('admin');
  console.log("safda",typeof(userId))
  // alert(businessId)

  
  if(!userId){
    navigate("/auth")
  }

  const baseUrl = baseUrll;
  // fetch api
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [upcomAppointment,setUpcomAppointment] = useState("") 

  const [prevAppointment,setPrevAppointment] = useState("")

  // const [upcomAppointment,setUpcomAppointment] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        // var userId = 1;
        // var businessId = 1;

        const response = await fetch(baseUrl + `services.get/${userId}/${businessId}`,{
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
          },
        });



        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log(result.upcomingAppointmen);
        // for(let i = 0 ; i < result.)
        setData(result);
        console.log(result)

         var appoint1  = await result.upcomingAppointment.find(item => item.userId == userId);
          console.log("dasdfsadfas",appoint1)

         setUpcomAppointment(appoint1);

         var appoint2  = result.pastAppointment.find(item => item.userId == userId);

         setPrevAppointment(appoint2);
        //  console.log(appoint1,appoint2)

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  
  
  
  
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [width, setWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(3);
  var slid = 3; 
  if(width<1200){
    slid = 2
  }
  if(width < 840){
    slid =1
  }
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: slid,
    slidesToScroll: 1,
    arrows: false, // Hide default arrows
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev(); // Go to previous slide
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext(); // Go to next slide
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
   
      <div className="disc">
        {/* <img src={discount} alt="" /> */}
      </div>

    <section>
      <h3>Upcoming Appointments</h3>
      
      <div className="carousel-navigation">
        <img src={prev} className="prev-button" onClick={goToPrevSlide}/>
        <img src={next} className="next-button" onClick={goToNextSlide}/>
          
      </div>

      <div className="carousel-container">
        <Slider style={{padding: "10px",}} ref={sliderRef} {...settings}>
          
        {upcomAppointment?.services?.map((item, index) => (
        
        // {if(data)}
        <Link to={`/index/upcomingAppointment/${item.id}`}>
           <div >

            <div className="card">
              <div className="up">
                <img src={baseUrl+item.image} alt="" />

                <div className="content">
                  {/* <h6>{item.status}</h6> */}
                  <h3>{item.serviceName}</h3>
                </div>
              
              </div>
              <p style={{
                  width:"100%",
                  color: "gray",
                  backgroundColor: "gray",
                  height: 1,
                  opacity:0.4,
                  marginTop:"-20px"
              }} ></p>
              
              <div className="bottom">
                <div className="content2">
                  <img src="" alt="" />
                  <h6> {item.time.from}</h6>
                </div>

                <div className="content2">
                  <img src="" alt="" />
                  <h6> {upcomAppointment.location}</h6>
                </div>

                
              </div>
            </div>
         
          </div>
        </Link>
       
     
        ))}

          <div style={{visibility:"hidden"}} >

            <div style={{visibility:"hidden"}} className="card">
              <div className="up">
                <img src="" alt="" />
                <div className="content">
                  <h6></h6>
                  <h3>Relax Mens Parlour</h3>
                </div>
              
              </div>
              <p style={{
                  width:"100%",
                  color: "gray",
                  backgroundColor: "gray",
                  height: 1,
                  opacity:0.4,
                  marginTop:"-20px"
              }} ></p>
              
              <div className="bottom">
                <div className="content2">
                  <img src="" alt="" />
                  <h6> Mariwala road</h6>
                </div>

                <div className="content2">
                  <img src="" alt="" />
                  <h6> Mariwala road</h6>
                </div>

                <div className="content2">
                  <img src="" alt="" />
                  <h6> Mariwala road</h6>
                </div>
              </div>
            </div>
         
          </div>
          
          {/* Add more slides as needed */}
        </Slider>
        {/* Navigation buttons */}
        
      </div>
    </section>

    
    <section>
        <h3>Previous Appointments</h3>
      
        <div className="carousel-navigation">
          <img src={prev} className="prev-button" onClick={goToPrevSlide}/>
          <img src={next} className="next-button" onClick={goToNextSlide}/>
          
        </div>

        <div className="carousel-container">
          <Slider style={{padding: "10px",}} ref={sliderRef} {...settings}>
          {prevAppointment?.services?.map((item, index) => (
         <Link to={`/index/previousAppointments/${item.id}`}>
        {/* // {if(data)} */}
        <div >

          <div className="card">
            <div className="up">
              <img src={baseUrl+item.image} alt="" />

              <div className="content">
                {/* <h6>{item.status}</h6> */}
                <h3>{item.serviceName}</h3>
              </div>
          
            </div>
            <p style={{
                width:"100%",
                color: "gray",
                backgroundColor: "gray",
                height: 1,
                opacity:0.4,
                marginTop:"-20px"
            }} ></p>
          
            <div className="bottom">
              <div className="content2">
                <img src="" alt="" />
                <h6> {item.time.from}</h6>
              </div>

              <div className="content2">
                <img src="" alt="" />
                <h6>{prevAppointment.location}</h6>
              </div>

             
            </div>
          </div>
     
        </div>
        </Link>
        ))}
            
            <div style={{visibility:"hidden"}} >

              <div style={{visibility:"hidden"}} className="card">
                <div className="up">
                  <img src="" alt="" />
                  <div className="content">
                    <h6></h6>
                    <h3>Relax Mens Parlour</h3>
                  </div>
              
                </div>
                <p style={{
                    width:"100%",
                    color: "gray",
                    backgroundColor: "gray",
                    height: 1,
                    opacity:0.4,
                    marginTop:"-20px"
                }} ></p>
              
                <div className="bottom">
                  <div className="content2">
                    <img src="" alt="" />
                    <h6> Mariwala road</h6>
                  </div>

                  <div className="content2">
                    <img src="" alt="" />
                    <h6> Mariwala road</h6>
                  </div>

                  <div className="content2">
                    <img src="" alt="" />
                    <h6> Mariwala road</h6>
                  </div>
                </div>
              </div>
         
            </div>
            {/* Add more slides as needed */}
          </Slider>
          {/* Navigation buttons */}
        
        </div>


    </section>

    <section>
          <h3>Hey, what's on your mind?</h3>
      
          <div className="carousel-navigation">
            <img src={prev} className="prev-button" onClick={goToPrevSlide}/>
            <img src={next} className="next-button" onClick={goToNextSlide}/>
          
          </div>

          <div className="carousel-container">
            <Slider style={{padding: "10px",}} ref={sliderRef} {...settings}>
              {data.services.map((item, index) => (
               <Link to={`/index/bookSlot`}>
              <div >

              <div className="card card2">
                <div className="up up2">
                  <img src={baseUrl+item.image} alt="" />
                  <h4>{item.serviceName}</h4>
            
                </div>
               
              </div>
       
              </div>
              </Link>
              ))}
             
              

              <div >

                <div style={{visibility:"hidden"}} className="card card2">
                  <div className="up up2">
                    <img src="" alt="" />
                    <h4>Spa</h4>
              
                  </div>
                 
                </div>
         
              </div>

          
             
              {/* Add more slides as needed */}
            </Slider>
            {/* Navigation buttons */}
        
          </div>
      </section>
    

      <section>
          <h3>Most Booked Services</h3>
      
          <div className="carousel-navigation">
            <img src={prev} className="prev-button" onClick={goToPrevSlide}/>
            <img src={next} className="next-button" onClick={goToNextSlide}/>
          
          </div>

          <div className="carousel-container">
            <Slider style={{padding: "10px",}} ref={sliderRef} {...settings}>
              {data.mostBookedServices.map((item, index) => (
               <Link to={`/index/bookSlot`}>
                <div >

                 <div className="card card2">
                   <div className="up up2">
                    {console.log(item.image)}
                     <img src={baseUrl+item.image} alt="" />
                     <h4>{item.serviceName}</h4>
             
                   </div>
                
                 </div>
        
                  </div>
               </Link>
               
              ))}
             

              <div >

                <div style={{visibility:"hidden"}} className="card card2">
                  <div className="up up2">
                    <img src="" alt="" />
                    <h4>Spa</h4>
              
                  </div>
                 
                </div>
         
              </div>

             
              {/* Add more slides as needed */}
            </Slider>
            {/* Navigation buttons */}
        
          </div>
      </section>
      <Banner/>

                
    </>
  );
};

export default Home;
import React,{useRef,useEffect,useState} from 'react';
import { Card } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Carousel.css'; // Custom CSS for styling

import prev from "../assets/images/prev.png"
import next from "../assets/images/next.png"
import banner from "../assets/images/bannerFooter.png"
import Banner from '../components/banner';
import discount from "../assets/images/discount.png"
import file from "../assets/images/Group(1).png"
import download from "../assets/images/download-minimalistic_svgrepo.com.png"
import call from "../assets/images/Frame 583.png"
import whatsapp from "../assets/images/whatsapp.png"
import star from "../assets/images/star.png"
import share from "../assets/images/share.png"
import web from "../assets/images/web.png"
import insta from "../assets/images/insta.png"
import face from "../assets/images/face.png"
import cut from "../assets/images/cut.png"
import tick from "../assets/images/tick.png"
import coupan from "../assets/images/Group.png"
import faq from "../assets/images/Vector.png"
import couponBg from "../assets/images/coupanBg.png"


import { FaChevronDown, FaChevronUp, FaCopy, FaMapMarkerAlt } from 'react-icons/fa';
import { CiLocationOn } from "react-icons/ci";
import { MdDateRange } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { BsPeople } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import "../assets/css/accordian.css"

import "../assets/css/coupon.css"
import { baseUrll } from '../utils/baseUrl';


import { useNavigate } from "react-router-dom";


const { Meta } = Card;

const Coupon = () => {


    const navigate = useNavigate();

    const userId = localStorage.getItem('user');
    const businessId = localStorage.getItem('business');
    const adminId = localStorage.getItem('admin');
    // alert(businessId)


    if(!userId){
      navigate("/auth")
    }

    // var adminId = 1
    const baseUrl = baseUrll;
    // fetch api
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const [upcomAppointment,setUpcomAppointment] = useState("") 
  
    const [prevAppointment,setPrevAppointment] = useState("")
  
    // const [upcomAppointment,setUpcomAppointment] = useState("")
    useEffect(() => {
      const fetchData = async () => {
        try {
          
  
          const response = await fetch(baseUrl + `coupons.find/${adminId}`,{
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              ['VerifyMe']: "RGVlcGFrS3VzaHdhaGE5Mzk5MzY5ODU0"
            },
          });
  
  
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const result = await response.json();
        //   console.log(result.upcomingAppointmen);
          // for(let i = 0 ; i < result.)
          setData(result.coupons);
        //   console.log(result)
  
        //    var appoint1  = await result.upcomingAppointment.find(item => item.userId === userId);
        //     console.log(appoint1)
  
        //    setUpcomAppointment(appoint1);
  
        //    var appoint2  = result.pastAppointment.find(item => item.userId === userId);
  
        //    setPrevAppointment(appoint2);
  
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []); // Empty dependency array means this effect runs once when the component mounts
  

    if (loading) {
        return <div>Loading...</div>;
      }


  return (
    <>
        <div className="coupanContainer">
            <h1>My Coupons</h1>
            <div className="row">

            {data.map((item, index) => (
              <div class="container" >
                <div className="container1">
                    <h1>{item.discount}% OFF</h1>
                    <img src={share} alt="" />
                </div>
                <div className="container2">
                    <h4>For {item.service} Services</h4>
                </div>
                <div className="container3">
                    <h4>Code: {item.code}</h4>
                    <FaCopy/>
                </div>
                <div className="container4">
                    <p>Valid till : {item.expireAt} <br /> 
                    Redemption limit : 01 <br />
                     </p>
                </div>
              
    
            </div>
            ))}
    
              
              
            </div>

            {/* <div className="row">
                <div class="container" >
                    <div className="container1">
                        <h1>5% OFF</h1>
                        <img src={share} alt="" />
                    </div>
                    <div className="container2">
                        <h4>For All Services</h4>
                    </div>
                    <div className="container3">
                        <h4>Code: NEWCUSTOMER_1234</h4>
                        <FaCopy/>
                    </div>
                    <div className="container4">
                        <p>Valid till : 05/08/2021 – 09/08/2021 <br /> 
                        Redemption limit : 01 <br />
                        Get 5% off when you spend over $169.00 </p>
                    </div>
              
    
                </div>

                <div class="container" >
                    <div className="container1">
                        <h1>5% OFF</h1>
                        <img src={share} alt="" />
                    </div>
                    <div className="container2">
                        <h4>For All Services</h4>
                    </div>
                    <div className="container3">
                        <h4>Code: NEWCUSTOMER_1234</h4>
                        <FaCopy/>
                    </div>
                    <div className="container4">
                        <p>Valid till : 05/08/2021 – 09/08/2021 <br /> 
                        Redemption limit : 01 <br />
                        Get 5% off when you spend over $169.00 </p>
                    </div>
              
    
                </div>
            </div> */}
        </div>
        
        



      <Banner/>

                
    </>
  );
};

export default Coupon;